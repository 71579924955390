<template>
  <div class="profile">
    <div v-if="user" class="wrap">
      <div v-if="user.room" class="myroom">
        <nuxt-link :to="`/rooms/${user.room._id}`" tag="button" class="wi active">
          <img src="/static/media/icons/door.png" alt="">
          <span v-if="isDesktop">Моя комната</span>
        </nuxt-link>
      </div>
      <div v-else></div>

      <div @click="showUserMenu = !showUserMenu" class="auth">
        <div class="avatar-wrap" :class="{ 'premium': user.group === 777 }">
          <!-- <i v-if="user.group === 777" class="crown" :class="{ 'patron': user.patron }" /> -->
          <div class="avatar">
            <img :src="user.photo" :alt="user.name">
            <!-- <sup v-if="user.noti.count" class="friendly">{{ user.noti.count }}</sup> -->
          </div>
        </div>
        <strong v-if="isDesktop">Профиль</strong>
      </div>
    </div>
    <div v-else>
      <nuxt-link :to="`/signin?ref=${encodeURI($route.fullPath)}`">
        <i class="icon-profile" />
        <strong>Войти</strong>
      </nuxt-link>
    </div>

    <transition name="toBottom">
      <div v-if="user && showUserMenu" class="user-menu">
        <!-- <div @click="hideSnow" style="position: relative;cursor:pointer;">
          <img src="/static_files/snow/snowflake.svg" alt="" style="position:absolute;top:0px;left:0px;width:24px;height:24px;">
          <div v-if="showSnow" class="snow-btn" />
        </div> -->

        <div style="display:grid;grid-template-columns:100px auto;grid-gap:10px;align-items:center;">
          <div>
            <input
              ref="avatarUpload"
              type="file"
              style="display:none;"
              accept="image/gif, image/png, image/jpeg, image/jpg"
              @change="uploadFile($event, 'avatar')"
            >
            <div class="avatar-wrap" :class="{ 'premium': user.group === 777 }">
              <!-- <i v-if="user.group === 777" class="crown" :class="{ 'patron': user.patron }" /> -->
              <div @click="avatarUploadTrigger" class="avatar">
                <img :src="user.photo" :alt="user.name">
                <span>Изменить</span>
              </div>
            </div>
          </div>

          <div>
            <input
              :data-user-id="user.id"
              :value="user.id"
              type="text"
              readonly
              style="position:absolute;top:-9999px;opacity:0;"
            />

            <div @click="shareID" style="font-size:1.2rem;color:rgba(255,255,255,0.5);cursor:pointer;">
              <span style="text-transform:uppercase;">ID:</span> {{ user.id }}
            </div>

            <div style="font-size:1.2rem;color:rgba(255,255,255,0.5);cursor:pointer;">
              <span style="text-transform:uppercase;">Email:</span> {{ user.email }}
            </div>

            <div style="margin:0px 0px 10px;">
              <strong>{{ user.name }}</strong>
              <i @click="showEditName = !showEditName" class="edit" />
            </div>

            <div style="font-size:1.2rem;text-transform:uppercase;cursor:pointer;">
              <nuxt-link :to="'/donate'" tag="span">
                <b
                  style="padding:2px 6px;color:#000;border-radius:20px;background-color:#FFD056;"
                >Premium</b>
                <u v-if="user.group === 777" style="font-weight:700;"> до {{ user.premium_date | filterDate }}</u>
                <u v-else style="cursor:pointer;font-weight:700;">активировать</u>
              </nuxt-link>
            </div>
          </div>
        </div>
      
        <div v-if="showEditName">
          <input v-model="newName" type="text" min="3" max="30" placeholder="Новый Никнейм">
          <div style="margin:10px 0px;">
            <button @click="changeName" class="mini">
              Изменить
            </button>
            <button @click="showEditName = !showEditName" class="mini">
              Отменить
            </button>
          </div>
          <p style="font-size:1.2rem;">Первая смена ника бесплатно, далее 10 Монет.</p>
        </div>

        <div class="row" style="text-align:right;font-size:1.4rem;">
          <!-- <nuxt-link to="/donate#views" tag="span">
          </nuxt-link> -->
          <template v-if="user.views">
            <!-- Приостановить просмотры без рекламы ({{ user.views }}) -->
            Смотреть с рекламой
            <div class="toggle" style="display: inline-block;">
              <input type="checkbox" id="cbx" v-model="user.views_pause" @change="viewsPause" />
              <label for="cbx" style="margin-left:5px;">
                <span>
                  <b>{{ user.views_pause ? $t('Да') : $t('Нет') }}</b>
                </span>
              </label>
            </div>
            <div style="margin-top:5px;">
              Просмотры без рекламы: {{ user.views }}
            </div>
          </template>
          <template v-else>
            <nuxt-link :to="'/donate#views'" tag="span">
              <b style="color:#F21A43;">Просмотры без рекламы закончились</b>
            </nuxt-link>
          </template>
        </div>

        <div class="row">
          <nuxt-link to="/donate" tag="span">
            Монеты: 
            <b style="color:#fff;">
              <img
                src="/static/media/icons/coin-dark.png"
                alt=""
                style="display:inline-block;position:relative;top:4px;width:20px;height:20px;"
              >
              {{ user.balance.toFixed(0) }} <b style="font-weight:700;color:#48A929;">+</b>
            </b>
          </nuxt-link>
        </div>

        <div class="row two">
          <nuxt-link :to="'/profile/favorites'" tag="span">Закладки</nuxt-link>
          <nuxt-link v-if="user.room" :to="`/rooms/${user.room._id}`" tag="span" style="color:#48A929;">Моя комната</nuxt-link>
          <div v-else></div>
        </div>

        <div class="row two">
          <nuxt-link :to="'/help'" tag="span">Поддержка</nuxt-link>
          <span @click="logout">Выйти</span>
        </div>

        <!-- <div class="msoc">
          <nuxt-link :to="localePath('/app')" class="app">
            <img src="/static/media/icons/smartphone.png" alt="">
            {{ $t('install_app') }}
          </nuxt-link>
          <a href="https://vk.com/unotaloneru" target="_blank">
            <img src="/static_files/social/vkontakte.png" class="vk" alt="">
          </a>
          <a href="https://t.me/unotaloneru" target="_blank">
            <img src="/static_files/social/telegram.png" class="tg" alt="">
          </a>
        </div> -->

        <i @click="showUserMenu = !showUserMenu" class="close" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user', 'isDesktop']),
    fullPath() {
      return this.$route.fullPath
    },
  },
  filters: {
    filterDate(value) {
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth() + 1
      const year = new Date(value).getFullYear()

      return `${day}.${month}.${year}`
    }
  },
  watch: {
    fullPath() {
      this.showEditName = false
      this.showUserMenu = false
    },
    viewsPauseVal(val) {
      if (val) {
        this.$store.commit('setError', { status: 'info', msg: 'Просмотры без рекламы приостановлены' })
      } else {
        this.$store.commit('setError', { status: 'info', msg: 'Просмотры без рекламы возобновлены' })
      }
      setTimeout(() => {
        this.$store.commit('clearError')
        location.reload()
      }, 1500)
    },
  },
  data() {
    return {
      showUserMenu: false,
      showEditName: false,
      newName: '',
      viewsPauseVal: null,
    }
  },
  methods: {
    async viewsPause() {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/user/views-pause`)
      this.loading = false
      const user = this.user
      user.views_pause = data
      this.viewsPauseVal = data
      this.$store.commit('setUser', user)
    },
    shareID() {
      const input = document.querySelector(`[data-user-id="${this.user.id}"]`)
      if (input) {
        input.select()
        document.execCommand('copy')
        this.$store.commit('setError', { status: 'ok', msg: 'ID скопирован' })
        setTimeout(() => this.$store.commit('clearError'), 1000)
      }
    },
    async changeName() {
      if (!this.user) return
      const badName = ['Alon', 'Pixel', 'bot', 'admin', 'administrator', 'unotalone', 'moderator', 'админ', 'бот', 'анимист', 'администратор']
      if (
        !this.newName.trim() ||
        badName.includes(this.newName.toLowerCase()) ||
        this.newName.match(/[^a-zA-Zа-яА-Я0-9 _].+/)
      ) {
        this.$store.commit('setError', { status: 'warn', msg: 'Неверный формат никнейма' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (this.newName.trim().length < 3) {
        this.$store.commit('setError', { status: 'warn', msg: 'Слишком короткое имя (мин. 3)' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else if (this.newName.trim().length > 30) {
        this.$store.commit('setError', { status: 'warn', msg: 'Слишком длинное имя (макс. 30)' })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        const { data } = await this.$axios.post('/api/user/nickname', { name: this.newName.trim() })
        if (data.error) {
          this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        } else {
          const user = this.user
          user.name = data.name
          this.$store.commit('setUser', user)
          this.newName = ''
          this.showEditName = false
          this.$store.commit('setError', { status: 'ok', msg: 'Никнейм успешно изменен' })
        }
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    avatarUploadTrigger() {
      this.$refs.avatarUpload.click()
    },
    uploadFile(event, typeImg) {
      const file = event.target.files[0]

      if (file) {
        const fileType = file.type
        const imageExt = file.name.slice(file.name.lastIndexOf('.'))
        const imageSize = file.size / 1024

        if (
          (imageExt === '.gif' && imageSize < 1000) ||
          (imageExt === '.jpeg' && imageSize < 5000) ||
          (imageExt === '.jpg' && imageSize < 5000) ||
          (imageExt === '.png' && imageSize < 5000)
        ) {
          const vm = this

          const reader = new FileReader()
          reader.readAsDataURL(file)

          reader.onloadend = function() {
            const image = new Image()
            image.src = reader.result

            image.onload = async function() {
              let maxWidth, maxHeight, finalFile

              if (typeImg === 'avatar') {
                maxWidth = 120
                maxHeight = 120
              }

              if (typeImg === 'avatar' && imageExt === '.gif') {
                finalFile = reader.result
              } else {
                let imageWidth = image.width
                let imageHeight = image.height

                if ((imageWidth > imageHeight) && (imageWidth > maxWidth)) {
                  imageHeight *= maxWidth / imageWidth
                  imageWidth = maxWidth
                } else if (imageHeight > maxHeight) {
                  imageWidth *= maxHeight / imageHeight
                  imageHeight = maxHeight
                }

                const canvas = document.createElement('canvas')
                canvas.width = imageWidth
                canvas.height = imageHeight

                const ctx = canvas.getContext('2d')
                ctx.drawImage(this, 0, 0, imageWidth, imageHeight)

                finalFile = canvas.toDataURL(fileType)
              }

              // The resized file ready for upload
              const fileName = `${vm.user.id}${imageExt}`

              const arr = finalFile.split(',')
              const mime = arr[0].match(/:(.*?);/)[1]
              const bstr = atob(arr[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)

              while (n--) u8arr[n] = bstr.charCodeAt(n)

              const imageFile = new File([u8arr], fileName, { type: mime })

              vm.$store.commit('setError', { status: 'load', msg: 'Идет загрузка' })

              const bodyFormData = new FormData()
              bodyFormData.append('image', imageFile)

              const { data } = await vm.$axios({
                method: 'post',
                url: `/upload?type=${typeImg}s`,
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              vm.$store.commit('setError', { status: 'ok', msg: 'Аватар установлен' })
              setTimeout(() => vm.$store.commit('clearError'), 1000)
              if (typeImg === 'avatar') {
                const photoUrl = data.url + '?' + Date.now()
                await vm.$axios.put('/api/user', { photo: photoUrl })
                const user = vm.user
                user.photo = photoUrl
                vm.$store.commit('setUser', user)
              }
            }
          }
        } else {
          this.$store.commit(
            'setError',
            'Файл не соответствует условиям: формат .gif, .jpg, .jpeg или .png размером до 5Mb'
          )
          setTimeout(() => this.$store.commit('clearError'), 3000)
        }
      } else {
        this.$store.commit('setError', 'Ошибка загрузки')
        setTimeout(() => this.$store.commit('clearError'), 2000)
      }
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      this.$store.commit('setUser', null)
      location.reload()
      // location.href = '/signin'
    },
  }
}
</script>

<style lang="stylus">
.profile
  position: relative
  .wrap
    display: grid
    grid-template-columns: auto auto
    grid-gap: 20px
  .toggle
    label
      position relative
      display inline-block
      width 40px
      cursor: pointer
      -webkit-tap-highlight-color: transparent
      transform: translate3d(0,0,0)
      &:before
        content: ""
        position: relative
        top: 3px
        left: 3px
        width: 34px
        height: 14px
        display: block
        background: #9A9999
        border-radius: 8px
        transition: background .2s ease
      span
        position: absolute
        top: 0
        left: 0
        width: 20px
        height: 20px
        display: inline-block
        background: white
        border-radius: 10px
        box-shadow: 0 3px 8px rgba(#9A9999,.5)
        transition: all .2s ease
        text-align: center
        &:before
          content: ""
          position: absolute
          display: block
          margin: -18px
          width: 56px
          height: 56px
          background: rgb(22, 195, 51)
          border-radius: 50%
          transform: scale(0)
          opacity: 1
          pointer-events: none
        b
          position: relative
          top: -3px
          color: #000
          font-size: 1rem
    input
      display: none
    input:checked + label
      &:before
        background: #16c333
      span
        transform: translateX(20px)
        transition: all .2s cubic-bezier(.8,.4,.3,1.25), background .15s ease
        box-shadow: 0 3px 8px rgba(#3e0352,.2)
        &:before
          transform: scale(1)
          opacity: 0
          transition: all .4s ease
  .auth
    display: grid
    grid-template-columns: 32px auto
    align-items: center
    grid-gap: 3px
    cursor: pointer
    .login
      padding: 6px 12px 6px 12px
      font-size: 1.4rem
      color: #fff
      border-radius: 4px
      background-color: rgba(251, 45, 127, 1)
      text-decoration: none
      transition: background .2s ease
      &:hover
        background-color: rgba(251, 45, 127, 1)
    .avatar-wrap
      margin-right: 10px
      position: relative
      width 32px
      height 32px
      padding: 3px
      background-color: rgba(0, 0, 0, 0.11)
      border-radius 50%
      &.premium
        background-image: url('/static/media/a54483bd82b6a251b78d381b66a6a4c5.svg')
        background-repeat: no-repeat
        background-size: 32px
      .avatar
        position relative
        width 26px
        height 26px
        border-radius 50%
        background: url('/static/media/default-cast.svg') center no-repeat
        background-size: 26px
        overflow hidden
        img
          width: 100%
          min-height: 100%
          object-fit: cover
  .user-menu
    position: absolute
    top: 45px
    right: 0px
    padding: 10px
    width: 320px
    background-color: #17171B
    border: 1px solid #212125
    border-radius: 20px
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1)
    z-index: 5
    .snow-btn
      position:absolute
      top:11px
      left:-5px
      width:32px
      height:2px
      transform:rotateZ(45deg)
      background-color:red
      opacity:0.55
    .balance
      margin-bottom: 20px
    strong
      font-size: 2rem
    .close
      position: absolute
      padding: 10px
      margin: 0 auto
      top: 5px
      right: 5px
      display: inline-block
      width: 28px
      height: 28px
      background: rgba(255, 255, 255, 0.15) url('/static/media/icons/close-dark.svg') center no-repeat
      background-size: 10px
      border-radius: 50%
      cursor: pointer
    .edit
      display: inline-block
      width: 14px
      height: 14px
      background-image: url('/static/media/icons/edit-dark.png')
      background-size: 14px
      cursor: pointer
      opacity: 0.55
      &:hover
        opacity: 1
    .avatar-wrap
      margin: 20px auto 20px
      position: relative
      width: 100px
      height: 100px
      padding: 3px
      background-color: rgba(0, 0, 0, 0.11)
      border-radius 50%
      &.premium
        padding: 7px
        background-image: url('/static/media/a54483bd82b6a251b78d381b66a6a4c5.svg')
        background-repeat: no-repeat
        background-size: 100px
        .avatar
          width: 86px
          height: 86px
      .avatar
        position: relative
        width: 94px
        height: 94px
        background: url('/static/media/default-cast.svg') center no-repeat
        border-radius: 50%
        overflow: hidden
        cursor: pointer
        img
          width: 100%
          min-height: 100%
          object-fit: cover
        span
          position: absolute
          width: 100%
          bottom: 0px
          left: 0px
          padding: 0px 4px 2px
          font-size: 0.8rem
          color: #f2f2f2
          background-color: rgba(0, 0, 0, 0.55)
          text-transform: uppercase
          text-align: center
          &:hover
            color: #f2f2f2
            text-decoration: underline
    .row
      display: block
      margin: 5px 0px
      padding: 10px 0px
      border-top: 1px solid rgba(255, 255, 255, 0.15)
      &.two
        display: grid
        grid-template-columns: repeat(2, 1fr)
        span:nth-child(even)
          justify-self: end
      span
        transition: color 0.2s ease
        cursor: pointer
        &:hover
          color: #fff
        sup.friendly
          position: relative
      i.plus
        display: inline-block
        width: 22px
        height: 22px
        background: #fff url('/static/media/icons/coin-donate.svg')
        background-size: 22px
        border-radius: 50%
    .msoc
      margin-top: 20px
      display: grid
      grid-template-columns: auto 24px 24px
      align-items: center
      grid-gap: 10px
      .app
        width: 160px
        display: grid
        grid-template-columns: 20px auto
        align-items: center
        grid-gap: 5px
        font-size: 1.2rem
        padding: 5px
        background-color: #3a3352
        border: 2px solid rgba(255, 255, 255, 0.1)
        border-radius: 4px
        img
          display: inline-block
          width: 20px
          height: 20px
      a
        display: inline-block
        opacity: 0.75
        transition: opacity 0.2s ease
        &:hover
          opacity: 1
        img
          &.vk, &.tg
            height: 24px
</style>